import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import SearchApp from '../../top/SearchApp';
import SearchScreen from '../../search/components/sp/SearchScreen';
import { buildSearchStore } from '../../search/store';
import { INITIALIZED } from '../../search/store/mutation-types';
import { initSlider } from '../../common/slider';

document.addEventListener('DOMContentLoaded', () => {
  // 横スクロールのボタン初期化
  initSlider();

  // 検索欄の初期化
  document.querySelectorAll('.js-top-page-search-components').forEach(el => {
    const store = buildSearchStore();
    store.commit(INITIALIZED);

    const app = new Vue({
      store: store,
      render: h => h(SearchApp)
    });
    app.$mount(el);

    const searchScreenEl = document.querySelector('.js-sp-search-screen');
    if (searchScreenEl) {
      const searchScreen = new Vue({
        store: store,
        render: h => h(SearchScreen)
      });
      searchScreen.$mount(searchScreenEl);
    }
  });

  // 「カテゴリから探す」のクリックをGA計測
  document
    .querySelectorAll('.js-category-ga-event')
    .forEach(elm =>
      elm.addEventListener('click', () =>
        sendGAEvent('サイトTOP', 'click', 'カテゴリから探す')
      )
    );
});
