<template>
  <div class="mobile-only">
    <trigger-button />
    <!-- trigger-buttonから起動されるsearch-screenは、body直下にあってstore経由で表示する -->
  </div>
</template>

<script>
import TriggerButton from './TriggerButton';
import SearchScreen from '../../search/components/sp/SearchScreen';

export default {
  components: { TriggerButton, SearchScreen }
};
</script>
